import http from '../../services/helpers/http';
import enums from '../../services/helpers/enums';
import i18n from '../../services/lang';

const validators = {
	async territoryUrlName() {
		if (!this.model.TerritoryUrlName || this.model.TerritoryUrlName.isNullOrWhiteSpace()) {
			return [i18n.t('amistake!')];
		}

		if (
			!this.model.TerritoryUrlName ||
			this.model.TerritoryUrlName.isNullOrWhiteSpace() ||
			this.model.TerritoryUrlName.indexOf(' ') >= 0
		) {
			return [i18n.t('amistake!')];
		}

		if (!this.model.TerritoryUrlName || this.model.TerritoryUrlName.withoutDiacritics() != this.model.TerritoryUrlName) {
			return [i18n.t('amistake!')];
		}

		let resp = await http.get('Territory/ExistsUrl', {
			territoryId: this.model.TerritoryId,
			url: this.model.TerritoryUrlName,
		});

		if (resp.data === true) {
			return [i18n.t('urlnamemustbeor')];
		}

		return [];
	},
	required() {
		switch (this.schema.model) {
			case 'TerritoryNameSk':
				if (
					this.model.CountryId === enums.country.SK &&
					(!this.model.TerritoryNameSk || this.model.TerritoryNameSk.length === 0)
				) {
					return [i18n.t('amistake!')];
				} else {
					return [];
				}
			case 'TerritoryNameLokalSk':
				if (
					this.model.CountryId === enums.country.SK &&
					(!this.model.TerritoryNameSk || this.model.TerritoryNameLokalSk.length === 0)
				) {
					return [i18n.t('amistake!')];
				} else {
					return [];
				}
			case 'TerritoryNameCz':
				if (
					this.model.CountryId === enums.country.CZ &&
					(!this.model.TerritoryNameCz || this.model.TerritoryNameCz.length === 0)
				) {
					return [i18n.t('amistake!')];
				} else {
					return [];
				}
			case 'TerritoryNameLokalCz':
				if (
					this.model.CountryId === enums.country.CZ &&
					(!this.model.TerritoryNameCz || this.model.TerritoryNameLokalCz.length === 0)
				) {
					return [i18n.t('amistake!')];
				} else {
					return [];
				}
			case 'TerritoryNamePl':
				if (
					this.model.CountryId === enums.country.PL &&
					(!this.model.TerritoryNamePl || this.model.TerritoryNamePl.length === 0)
				) {
					return [i18n.t('amistake!')];
				} else {
					return [];
				}
			case 'TerritoryNameLokalPl':
				if (
					this.model.CountryId === enums.country.PL &&
					(!this.model.TerritoryNamePl || this.model.TerritoryNameLokalPl.length === 0)
				) {
					return [i18n.t('amistake!')];
				} else {
					return [];
				}
		}
	},
};

export default validators;
