<template>
	<detail-page
		v-if="isLoaded"
		:ref="detailPageRefName"
		:formModel="model"
		:formSchema="schema"
		:showActionDelete="showDeleteButton"
		tooltipMsg="manualforlocali3332"
		@saveForm="saveModel($event)"
		@closeDetail="redirectToTable()"
		@delete="openModal()"
	>
		<template #header>
			{{ $t('detailof') }}
			<b-link target="_blank" :href="`${model.DomainUrl + model.TerritoryUrlName}`">
				{{ model.CountryId === 1 ? model.TerritoryNameSk : model.TerritoryNameCz }}
			</b-link>
			<label>&nbsp;{{ $t('id') }}&nbsp;{{ model.TerritoryId }}</label>
		</template>

		<template #beforeFormActions>
			<google-map
				:latitude="+model.TerritoryGoogleMapsGpsLat"
				:longitude="+model.TerritoryGoogleMapsGpsLng"
				:boundaries="model.TerritoryBoundaries"
				:zoom="+model.TerritoryGoogleMapsZoom"
				:onChanged="coordinatesChanged"
				height="700"
				:boundariesEnabled="false"
			></google-map>
		</template>

		<template #afterForm>
			<city-sub-table
				v-if="model.DomainUrl"
				tableName="citiesInTerritory"
				:locationId="detailEntityId"
				locationQueryName="TerritoryId"
				:domainUrl="model.DomainUrl"
				:title="$t('citiesinthecoun')"
			></city-sub-table>
		</template>
	</detail-page>
</template>

<script>
import vue from 'vue';
import DetailPage from '@/components/general/detail-page';
import { detailPageMixin } from '@/components/general/detail-page.mixin';
import { model, fields } from './territory-form.data';

import apiCommon from '../../services/api/common.api';
import serviceCommon from '../../services/service/common.service';
import * as links from '../.././router/links';
import loading from '../../mixins/loading.mixin';

export default {
	components: {
		DetailPage,
	},

	mixins: [loading, detailPageMixin],

	data() {
		return {
			controllerName: 'Territory',
			routeParam: 'TerritoryId',
			model,
			schema: {
				groups: fields,
			},
		};
	},

	computed: {
		showDeleteButton() {
			return !!this.model.TerritoryId;
		},
	},

	created() {
		/* TODO overit ci to netreba presunut do globalneho nastavenia
		var res = vueFormGenerator.validators.resources;
		res.fieldIsRequired = this.$i18n.t('amistake!');
		*/
	},

	methods: {
		async loadResources() {
			this.getCountryEnum();
		},

		transformDtoToModel(dtoModel) {
			dtoModel.TerritoryGoogleMapsGpsLat = +dtoModel.TerritoryGoogleMapsGpsLat;
			dtoModel.TerritoryGoogleMapsGpsLng = +dtoModel.TerritoryGoogleMapsGpsLng;
			return dtoModel;
		},

		async coordinatesChanged(lat, lng, zoom, polygonPaths) {
			const toModelUpdate = {};
			if (lat) {
				toModelUpdate.TerritoryGoogleMapsGpsLat = lat;
			}
			if (lng) {
				toModelUpdate.TerritoryGoogleMapsGpsLng = lng;
			}
			if (zoom) {
				toModelUpdate.TerritoryGoogleMapsZoom = zoom;
			}
			if (polygonPaths) {
				toModelUpdate.TerritoryBoundaries = JSON.stringify(polygonPaths);
			}
			this.updateFormModel(toModelUpdate);
			await this.$refs[this.detailPageRefName].validate();
		},

		openModal() {
			serviceCommon.openDeleteModal(this.model.TerritoryId, this.$modal, this.deleteModel);
		},

		getCountryEnum() {
			this.updateSchemaFields({ CountryId: { values: this.$store.state.country.data } });
		},
	},
};
</script>
