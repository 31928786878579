var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLoaded
    ? _c("detail-page", {
        ref: _vm.detailPageRefName,
        attrs: {
          formModel: _vm.model,
          formSchema: _vm.schema,
          showActionDelete: _vm.showDeleteButton,
          tooltipMsg: "manualforlocali3332",
        },
        on: {
          saveForm: function ($event) {
            return _vm.saveModel($event)
          },
          closeDetail: function ($event) {
            return _vm.redirectToTable()
          },
          delete: function ($event) {
            return _vm.openModal()
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "header",
              fn: function () {
                return [
                  _vm._v("\n\t\t" + _vm._s(_vm.$t("detailof")) + "\n\t\t"),
                  _c(
                    "b-link",
                    {
                      attrs: {
                        target: "_blank",
                        href:
                          "" +
                          (_vm.model.DomainUrl + _vm.model.TerritoryUrlName),
                      },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t" +
                          _vm._s(
                            _vm.model.CountryId === 1
                              ? _vm.model.TerritoryNameSk
                              : _vm.model.TerritoryNameCz
                          ) +
                          "\n\t\t"
                      ),
                    ]
                  ),
                  _c("label", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("id")) +
                        " " +
                        _vm._s(_vm.model.TerritoryId)
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "beforeFormActions",
              fn: function () {
                return [
                  _c("google-map", {
                    attrs: {
                      latitude: +_vm.model.TerritoryGoogleMapsGpsLat,
                      longitude: +_vm.model.TerritoryGoogleMapsGpsLng,
                      boundaries: _vm.model.TerritoryBoundaries,
                      zoom: +_vm.model.TerritoryGoogleMapsZoom,
                      onChanged: _vm.coordinatesChanged,
                      height: "700",
                      boundariesEnabled: false,
                    },
                  }),
                ]
              },
              proxy: true,
            },
            {
              key: "afterForm",
              fn: function () {
                return [
                  _vm.model.DomainUrl
                    ? _c("city-sub-table", {
                        attrs: {
                          tableName: "citiesInTerritory",
                          locationId: _vm.detailEntityId,
                          locationQueryName: "TerritoryId",
                          domainUrl: _vm.model.DomainUrl,
                          title: _vm.$t("citiesinthecoun"),
                        },
                      })
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          702208517
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }