import vueFormGenerator from 'vue-form-generator';
import validators from './territory.validators';
import { formatter } from '../../services/helpers/formatter';

export const model = {
	TerritoryId: 0,
	TerritoryNameSk: null,
	TerritoryNameLokalSk: null,
	TerritoryNameCz: null,
	TerritoryNameLokalCz: null,
	TerritoryNamePl: null,
	TerritoryNameLokalPl: null,
	TerritoryNameDe: null,
	TerritoryNameLokalDe: null,
	TerritoryNameEn: null,
	TerritoryNameLokalEn: null,
	TerritoryNameHu: null,
	TerritoryNameLokalHu: null,
	TerritoryUrlName: null,
	CountryId: null,
	TerritoryGoogleMapsGpsLat: null,
	TerritoryGoogleMapsGpsLng: null,
	TerritoryGoogleMapsZoom: null,
};

export const fields = [
	{
		fields: [
			{
				type: 'input',
				inputType: 'text',
				model: 'TerritoryNameSk',
				validator: validators.required,
				i18n: {
					label: 'namesk2556',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'TerritoryNameLokalSk',
				validator: validators.required,
				i18n: {
					label: 'localsk2557',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'TerritoryNameCz',
				validator: validators.required,
				i18n: {
					label: 'namecz2558',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'TerritoryNameLokalCz',
				validator: validators.required,
				i18n: {
					label: 'localcz2559',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'TerritoryNamePl',
				validator: validators.required,
				i18n: {
					label: 'namepl2561',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'TerritoryNameLokalPl',
				validator: validators.required,
				i18n: {
					label: 'localcz2560',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'TerritoryNameEn',
				validator: validators.required,
				i18n: {
					label: 'nameen2564',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'TerritoryNameLokalEn',
				validator: validators.required,
				i18n: {
					label: 'localen',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'TerritoryNameDe',
				validator: validators.required,
				i18n: {
					label: 'namede2562',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'TerritoryNameLokalDe',
				validator: validators.required,
				i18n: {
					label: 'localde2563',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'TerritoryNameHu',
				validator: validators.required,
				i18n: {
					label: 'namehu2566',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'TerritoryNameLokalHu',
				validator: validators.required,
				i18n: {
					label: 'localhu2567',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'TerritoryUrlName',
				validator: validators.territoryUrlName,
				required: true,
				i18n: {
					label: 'urlname',
				},
			},
			{
				type: 'select',
				model: 'CountryId',
				required: true,
				validator: vueFormGenerator.validators.integer,
				values: [],
				selectOptions: {
					noneSelectedText: '',
					value: 'Id',
					name: 'Name',
				},
				styleClasses: 'half-width',
				i18n: {
					label: 'country',
					selectOptions: {
						noneSelectedText: 'select',
					},
				},
			},
			{
				type: 'input',
				inputType: 'number',
				model: 'TerritoryGoogleMapsGpsLat',
				required: true,
				validator: vueFormGenerator.validators.double,
				i18n: {
					label: 'latitude',
				},
			},
			{
				type: 'input',
				inputType: 'number',
				model: 'TerritoryGoogleMapsGpsLng',
				required: true,
				validator: vueFormGenerator.validators.double,
				i18n: {
					label: 'longitude',
				},
			},
			{
				type: 'input',
				inputType: 'number',
				model: 'TerritoryGoogleMapsZoom',
				required: true,
				validator: vueFormGenerator.validators.integer,
				i18n: {
					label: 'zoomonmap',
				},
			},
		],
	},
];
